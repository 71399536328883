exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-liput-tsx": () => import("./../../../src/pages/liput.tsx" /* webpackChunkName: "component---src-pages-liput-tsx" */),
  "component---src-pages-ohjeet-kavijaopas-tsx": () => import("./../../../src/pages/ohjeet/kävijäopas.tsx" /* webpackChunkName: "component---src-pages-ohjeet-kavijaopas-tsx" */),
  "component---src-pages-ohjeet-saapuminen-tsx": () => import("./../../../src/pages/ohjeet/saapuminen.tsx" /* webpackChunkName: "component---src-pages-ohjeet-saapuminen-tsx" */),
  "component---src-pages-ohjeet-tapahtumakartta-tsx": () => import("./../../../src/pages/ohjeet/tapahtumakartta.tsx" /* webpackChunkName: "component---src-pages-ohjeet-tapahtumakartta-tsx" */),
  "component---src-pages-ohjeet-vanhemmille-tsx": () => import("./../../../src/pages/ohjeet/vanhemmille.tsx" /* webpackChunkName: "component---src-pages-ohjeet-vanhemmille-tsx" */),
  "component---src-pages-ohjelma-aikataulu-tsx": () => import("./../../../src/pages/ohjelma/aikataulu.tsx" /* webpackChunkName: "component---src-pages-ohjelma-aikataulu-tsx" */),
  "component---src-pages-ohjelma-turnaukset-tsx": () => import("./../../../src/pages/ohjelma/turnaukset.tsx" /* webpackChunkName: "component---src-pages-ohjelma-turnaukset-tsx" */),
  "component---src-pages-saannot-tsx": () => import("./../../../src/pages/saannot.tsx" /* webpackChunkName: "component---src-pages-saannot-tsx" */)
}

